.plans_container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans_card {
    display: flex;
    align-items: center;
    gap: 3rem;
    justify-content: center;
}

.plans_card>:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
}

.plans_card>:nth-child(2) button {
    color: orange;
}

.plan {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: white;
    background: var(--caloryCard);
    padding: 1.5rem;
    width: 15rem;
}

.plan>span:nth-of-type(1) {
    font-weight: bold;
    font-size: 1.6rem;
}

.plan>span:nth-of-type(2) {
    font-weight: bold;
    font-size: 2.5rem;
}

.see_more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.see_more>span {
    font-size: 0.8rem;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    gap: 1rem;
    font-weight: normal;
    font-size: 0.8rem;
}

.feature>img {
    width: 1.5rem;
    height: 1.5rem;
}

.plans_blur_1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}

.plans_blur_2 {
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}

@media (max-width: 768px) {
    .plans_container {
        justify-content: center;
        align-items: center;
    }

    .plans_card {
        flex-direction: column;
        width: 100%;
    }

    .plan>span:nth-of-type(1) {
        font-weight: bold;
        font-size: 1rem;
        text-align: center;
    }
    
    .plan>span:nth-of-type(2) {
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
    }
    .plans_blur_1 {
        width: 22rem;
        height: 13rem;
        top: 6rem;
        left: 0rem;
    }
    
    .plans_blur_2 {
        width: 22rem;
        height: 13rem;
        top: 10rem;
        right: 0rem;
    }
    .programs_header {
        text-align: center;
    }
}