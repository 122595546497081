.hero {
    display: flex;
    justify-content: space-between;
}

/* Left Section  */

.left_h {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.the_best_ads {
    margin-top: 4rem;
    background: #363D42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.the_best_ads>span {
    z-index: 2;
}

.the_best_ads>div {
    background: var(--orange);
    position: absolute;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero_text>div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures {
    display: flex;
    gap: 1rem;
}

.figures>div {
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
}

.hero_buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero_buttons>:nth-child(1) {
    color: white;
    background: var(--orange);
    width: 8rem;
}

.hero_buttons>:nth-child(2) {
    color: white;
    background: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

/* Right Section  */
.right_h {
    flex: 1;
    position: relative;
    background: var(--orange);
}

.right_h>.btn {
    position: absolute;
    right: 3rem;
    top: 2rem;
}

.heart_rate {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 2rem;
    top: 7rem;
}

.heart_rate>img {
    width: 2rem;
}

.heart_rate>:nth-child(2) {
    color: var(--gray);
}

.heart_rate>:nth-child(3) {
    color: white;
    font-size: 1.5rem;
}

.hero_image {
    position: absolute;
    top: 8rem;
    right: 10rem;
    width: 20rem;
    border: 5px solid var(--orange);
    border-top-right-radius: 250px;
    border-top-left-radius: 250px;
}

.hero_blur {
    width: 22rem;
    height: 30rem;
    border: 1px solid red;

    left: 0;
}

.calories {
    display: flex;
    gap: 2rem;
    background: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem 0.5rem;
    width: fit-content;
    position: absolute;
    top: 35rem;
    right: 30.5rem;
}

.calories>img {
    width: 5rem;
    height: 5rem;
}

.calories>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: max-content;
}

.calories>div>:first-child {
    color: var(--gray);
}

.calories>div>:nth-child(2) {
    color: white;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
    }

    .hero_blur {
        width: 14rem;
        height: 30rem;
        border: 1px solid red;

        left: 0;
    }

    .the_best_ads {
        margin-top: 0rem;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero_text {
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }

    .hero_text>div:nth-of-type(3) {
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .hero_buttons {
        justify-content: center;
    }

    .figures>div>span:nth-of-type(1) {
        color: white;
        font-size: larger;
    }

    .figures>div>span:nth-of-type(2) {
        font-size: small;
    }
    
    .right_h {
        position: relative;
        background: none;
    }
    
    .heart_rate {       
        left: 1rem;
        top: 2rem;
    }

    .calories {       
        position: relative;
        top: 5rem;
        left: 2rem;
    }

    .calories>img {
        width: 2rem;
        height: 3rem;
    }
       
    .calories>div>:nth-child(2) {
        color: white;
        font-size: 1rem;
    }

    .hero_image {
        position: relative;
        top: 4rem;
        left: 3rem;
        width: 14rem;
        align-self: center;
 
    }
}