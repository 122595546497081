.join_container {
    display: flex;
    gap: 10rem;
    padding: 0 2rem;    
}

.join_container>div {
    width: 48%;
}

.join_left_side {
    color: white;
    font-size: 2.8rem;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
}

.read_text {
    border-top: 3px solid var(--orange);
    border-radius: 7%;
}

/* Right Side */
.join_right_side {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email_container {
    display: flex;
    gap: 3rem;
    background: gray;
    padding: 1rem 2rem;
}

form>input {
    background: transparent;
    outline: none;
    border: none;
    color: lightgray;
}

form>input::placeholder {
    color: whitesmoke;
}

.btn_join {
    background: var(--orange);
    color: white;
    border-radius: 5px;
}


@media (max-width: 768px) {
    .join_container {
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .join_container>div {
        width: 90%;
    }

    .join_left_side {
        color: white;
        font-size: 2rem;
        font-weight: bold;
    }


    .join_right_side {
        display: flex;
        justify-content: center;
        align-items: center
    }

    .email_container {
        display: flex;
        gap: 0rem;
        background: gray;
        padding: 1rem;
        border-radius: 5px;
    }

    form>input {
        background: transparent;
        outline: none;
        border: none;
        color: lightgray;
    }

}