.reason_container {
    display: flex;
    gap: 2rem;
    padding: 0rem 2rem;
}

.reason_container>div {
    width: 48%;
}

/* Left Side  */
.left_side_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
    grid-auto-rows: 1fr;
}

.left_side_container>:nth-child(1) {
    width: 12rem;
    height: 100%;
    grid-row: 1/ span 2;
}

.left_side_container>:nth-child(2) {
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 2;
}

.left_side_container>:nth-child(3) {
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 3;
}

.left_side_container>:nth-child(4) {
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 2;
}
.left_side_container>:nth-child(5) {
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 3;
}
.left_side_container>img {
    object-fit: cover;
}



/* Right Side */
.right_side_container {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 1rem;
}

.right_side_container > span {
    font-weight: bold;
    color: var(--orange);
}

.right_side_container > div {
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.right_side_detials {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.right_side_detials > div {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
    font-style: italic;
}

.right_side_detials > div > img {
    width: 1.5rem;
}

.our_partners {
    display: flex;
    gap: 2rem;
}

.our_partners > img {
    width: 2.5rem;
}


@media (max-width: 768px) {
    .reason_container {
      flex-direction: column;
      padding: 0 1rem;
    }
    .reason_container>div {
        width: 100%;
    }
    .reason_container .left_side_container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        justify-content: center;
        align-items: center;
    }
    
    .reason_container >.left_side_container>img {
        width: 90%;
        height: 10rem;
    } 
     
    .right_side_container > span {
        font-weight: bold;
        color: var(--orange);
    }
    
    .right_side_container > div {
        color: white;
        font-size: 2rem;
        font-weight: bold;
    }
    
    .right_side_detials {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .right_side_detials > div {
        display: flex;
        font-size: 0.7rem;
        gap: 0.5rem;
        font-style: italic;
    }
    
    .right_side_detials > div > img {
        width: 1rem;
    }

    .our_partners {
        display: flex;
        gap: 3rem;
    }
    
    .our_partners > img {
        width: 3.5rem;
    }
    
}