.footer_container {
    position: relative;
}

.footer_container > hr {
    border: 1px solid var(lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex; 
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
}

.social_links {
    display: flex;
    gap: 4rem;
    cursor: pointer;
    padding-top: 3rem;
}

.social_links > img {
    width: 3rem;
} 
.app_logo > img {
    width: 8rem;
}

.footer_blur_1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}

.footer_blur_2 {
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}

.brand {
    font-size: 0.6rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .footer_container {
        position: relative;
    }
    
    .footer_container > hr {
        border: 1px solid var(lightgray);
    }
    
    .footer {
        padding: 0rem 2rem;
        display: flex; 
        flex-direction: column;
        gap: 0rem;
        align-items: center;
        justify-content: center;
    }
    
    .social_links {
        display: flex;
        gap: 1rem;
        cursor: pointer;
        padding-top: 3rem;
    }
    
    .social_links > img {
        width: 2rem;
    } 
    .app_logo > img {
        width: 5rem;
    }
    
    .footer_blur_1 {
        width: 22rem;
        height: 13rem;
        top: 6rem;
        left: 0rem;
    }
    
    .footer_blur_2 {
        width: 22rem;
        height: 13rem;
        top: 10rem;
        right: 0rem;
    }
}