.programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs_header {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2.5rem;
    justify-content: space-around;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.program_categories {
    display:  flex;
    gap: 1rem;
}

.category {
    display: flex;
    flex-direction: column;
    background: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
    width: 30%
}

.category >:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: white;
}

.category >:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.category >:nth-child(3) {
    font-size: 0.9rem;
    line-height: 25px;
}

.category:hover {
    background: var(--planCard);
    cursor: pointer;
}

.join_now {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.join_now > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.join_now >:first-child {
    border: 2px solid white;
    padding: 0.5rem;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .programs_header {
       flex-direction: column;
       gap: 1rem;
       font-size: x-large;
       align-items: center;
       justify-content: center;
       margin-top: 2rem;
    }

    .program_categories {
        flex-direction: column;
        width: max-content;        
    }
    .category {        
        width: 35%
    }
    
}