.testimonials {
    display: flex;
    padding: 0rem 2rem;
    gap: 2rem;
}
.testimonials > div {
    width: 48%;
}

.left_side {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 2rem;
    color: white;
}

.left_side > :nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}

.left_side>:nth-child(2),
.left_side>:nth-child(3) {
    font-weight: bold;
    font-size: 3rem;
}

.left_side>:nth-child(4) {
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right_side {
    display: flex;
    position: relative;
}

.right_side > img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;

    right: 8rem;
    top: 2rem;
}

.right_side>:nth-child(1) {
    width: 17rem;
    height: 20rem;
    border: 2px solid var(--orange);
    background: transparent;
    position: absolute;
    right: 9rem;
    top: 0.9rem;
}

.right_side>:nth-child(2) {
    width: 17rem;
    height: 19rem;
    background: var(--planCard);
    position: absolute;
    right: 7rem;
    top: 4rem;
}

.arrow > .inner_arrow {
    fill: orange;
    font-size: 3rem;
    cursor: pointer;
}

.arrow {
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 3rem;
    bottom: 1rem;
}




@media (max-width: 768px) {

    .testimonials {
        flex-direction: column;
        padding: 0rem 2rem;
        gap: 0rem;
    }
    .testimonials  div {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .left_side {
        flex-direction: column;
        position: absolute;
        justify-content: center;
        align-items: center;
    }  
    
    
    .left_side > :nth-child(1) {
        color: var(--orange);
        font-weight: bold;
        
    }
    
    .left_side>:nth-child(2),
    .left_side>:nth-child(3) {
        font-weight: bold;
        font-size: 2rem;
    }
    
    .left_side>:nth-child(4) {
        text-transform: none;
        text-align: center;
        letter-spacing: normal;
        line-height: normal;
        font-size: 1rem;
        font-family:'Times New Roman', Times, serif;
    }
    .right_side {
        flex-direction: column;
        position: absolute;
       
    }
    
    .right_side > img {
        position: relative;
        width: 8rem;
        height: 10rem;
        object-fit: cover;    
        right: -4rem;
        top: 2rem;
    }
    
    .right_side>:nth-child(1) {
        width: 8rem;
        height: 10rem;
        position: absolute;
        right: 4rem;
        top: 0.9rem;
    }
    
    .right_side>:nth-child(2) {
        width: 8rem;
        height: 10rem;
        position: absolute;
        right: 3rem;
        top: 3rem;
    }

}